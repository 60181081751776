// THIS IS A GENERATED FILE

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    CancellationInsuranceOfferResponse: ['CancellationInsuranceOffer', 'NoOffer'],
    ChangeSeatsResponse: ['ChangeSeatsError', 'SalesSession'],
    CreateSeriesBundleOffersResponse: ['NoConnections', 'SeriesBundleOffers'],
    FinalizePaymentResponse_v2: ['PaymentFinalized', 'PaymentFinalizedWithInfo'],
    MultiTicketsResponse: ['FailedToFetchResponse', 'MultiTickets'],
    OfferOrNot: ['NoOffer', 'Offer'],
    PriceCalendarResponse: ['Calendar', 'NoOffer'],
    UnpaidGroupOrdersResponse: ['FailedToFetchResponse', 'UnpaidGroupOrders'],
    UpdateCabinsResponse: ['SalesSession', 'UpdateCabinsErrorResponse'],
    UpdateInvoiceResponse: ['InvoiceNotEditable', 'Order'],
    UserDetails: ['ConsumerUserDetails', 'CorporateUserDetails', 'EmployeeUserDetails'],
    UserInfo: ['CiamUserInfo', 'ConsumerUserInfo', 'CorporateUserInfo', 'EmployeeUserInfo'],
    UserResponse: ['NotLoggedIn', 'User'],
  },
};
export default result;
