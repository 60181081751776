import { Workbox } from 'workbox-window';

import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import routes from '../../service-worker/routes';

export const useCacheRoutes = () => {
  const pathname = usePathname();
  const [initialPathname] = useState(pathname);

  useEffect(() => {
    if (
      process.env.NODE_ENV === 'development' ||
      routes.includes(initialPathname) // do not update the cache if user is on cached route
    ) {
      return;
    }

    const update = async () => {
      try {
        const wb = new Workbox('/sw.js');
        await wb.register();

        await wb.messageSW({
          action: 'CACHE_ROUTES',
          routes,
        });
      } catch (e) {
        // service workers disabled probably
      }
    };

    update();
  }, [initialPathname]);
};
