import { ApolloProvider } from '@apollo/client';
import { useState } from 'react';

import { BackendClient } from 'backend/BackendClient';

import { BackendClientProvider } from 'contexts/backendClient';

import { TokenRefreshLink } from 'backend/TokenRefreshLink';
import envs from 'utils/envs';
import { getSessionId } from 'utils/getSessionId';

interface Props {
  children: React.ReactNode;
}

export function BackendProvider({ children }: Props) {
  const [backendClient] = useState(
    () =>
      new BackendClient({ uri: envs.BACKEND_URL }, getSessionId(), [
        new TokenRefreshLink(), // TokenRefresh link is given here because we don't need it in the BackendClient for builds
      ]),
  );

  return (
    <BackendClientProvider value={backendClient}>
      <ApolloProvider client={backendClient}>{children}</ApolloProvider>
    </BackendClientProvider>
  );
}
