import { NextWebVitalsMetric } from 'next/app';

import { isNextWebVitalsMetric, isWebVitalsEvent } from 'types/typeGuards';

import pushToDataLayer from './pushToDataLayer';

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (isNextWebVitalsMetric(metric)) {
    const { id, name, value, startTime } = metric;
    const prevStartTime = Math.max(
      ...(window.dataLayer ?? []).reduce<number[]>(
        (memo, item) => {
          if (isWebVitalsEvent(item) && item.webVitalsMeasurement.name === name) {
            memo.push(item.webVitalsMeasurement.startTime);
          }
          return memo;
        },
        [0],
      ),
    );

    pushToDataLayer({
      event: 'coreWebVitals',
      webVitalsMeasurement: {
        name,
        id,
        value,
        startTime,
        delta: startTime - prevStartTime,
        valueRounded: Math.round(value),
        startTimeRounded: Math.round(startTime),
      },
    });
  }
}
