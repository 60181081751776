import { useEffect, useRef } from 'react';

export const useFocusNewHeadings = () => {
  const currentHeading = useRef<HTMLHeadingElement>();

  useEffect(() => {
    currentHeading.current = document.querySelector('h1') ?? undefined;

    const observer = new MutationObserver(() => {
      const heading = document.querySelector('h1');

      if (heading && heading !== currentHeading.current) {
        if (heading.tabIndex === -1) {
          heading.tabIndex = -1;
        }
        heading.focus();
        currentHeading.current = heading;
      }
    });

    observer.observe(document.body, { subtree: true, childList: true });

    return () => {
      observer.disconnect();
    };
  }, []);
};
