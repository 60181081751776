'use client';

import { SiteData } from 'build/data/getSiteData';

import { SiteDataProvider as ContextProvider } from 'contexts/siteData';

interface Props {
  children: React.ReactNode;
  data: SiteData;
}

export const SiteDataClientProvider = ({ children, data }: Props) => {
  return <ContextProvider value={data}>{children}</ContextProvider>;
};
