import gql from 'graphql-tag';

import { UserFragment } from 'backend/fragments/backend-fragments';
import { GET_USER_DETAILS_QUERY } from 'backend/hooks/useGetUserDetails';
import {
  GetUserDetailsQuery,
  UpdateUserDetailsMutation,
  UpdateUserDetailsMutationVariables,
} from 'backend/types.codegen';

import { BackendClient } from '../BackendClient';

const mutation = gql`
  ${UserFragment}

  mutation updateUserDetails($input: UpdateUserDetailsInput!) {
    updateUserDetails(input: $input) {
      ...User
    }
  }
`;

export const updateUserDetails =
  (client: BackendClient) => async (variables: UpdateUserDetailsMutationVariables) => {
    const data = await client.mutateData<
      UpdateUserDetailsMutation,
      UpdateUserDetailsMutationVariables
    >({
      mutation,
      variables,
      update: (_cache, { data }) => {
        // Set user { customerId details } query cache from updateUserDetails mutation response.
        // -> No need to fetch query separately.
        const userStatus = data?.updateUserDetails;
        if (userStatus) {
          client.writeQuery<GetUserDetailsQuery>({
            query: GET_USER_DETAILS_QUERY,
            data: { userStatus },
          });
        }
      },
    });

    return data.updateUserDetails;
  };
