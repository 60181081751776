import { useEffect } from 'react';

export const useDynamicViewportHeight = () => {
  useEffect(() => {
    const updateVH = () => {
      document.documentElement.style.setProperty('--vh', window.innerHeight / 100 + 'px');
    };

    updateVH();
    window.addEventListener('resize', updateVH);

    return () => {
      window.removeEventListener('resize', updateVH);
    };
  }, []);
};
