import Script from 'next/script';

import { useCookieConsent } from 'hooks/useCookieConsent';

import { requestIdleCallback } from 'utils/browser';
import envs from 'utils/envs';
import { useOnRouteChange } from 'utils/navigation';

declare global {
  interface Window {
    _conv_q?: string[][];
  }
}

const ConvertComChildren = ({ convertScript }: { convertScript: string }) => {
  useOnRouteChange(() => {
    window.setTimeout(() => {
      requestIdleCallback(() => {
        window._conv_q = window._conv_q || [];
        window._conv_q.push(['run', 'true']);
      }, 100);
    }, 100);
  });

  return <Script strategy="afterInteractive" src={convertScript} />;
};

export const ConvertComScript = () => {
  const [cookieConsent] = useCookieConsent();

  const convertScript = envs.NEXT_PUBLIC_CONVERT_SCRIPT;

  if (cookieConsent.level < 3 || !convertScript) {
    return null;
  }

  return <ConvertComChildren convertScript={convertScript} />;
};
