import { clearStoredState, setStoredState } from 'hooks/useStoredState';

import { log } from 'logging/log';

import { StorageKey } from 'types/stateStore';
import { isUserIdentityFragment } from 'types/typeGuards';

import envs from 'utils/envs';
import { getSessionId } from 'utils/getSessionId';
import { generateRandomId } from 'utils/misc';

export const refreshTokens = async () => {
  try {
    const response = await fetch(`${envs.NEXT_PUBLIC_API_PATH}/token/refresh`, {
      method: 'POST',
      headers: {
        ['x-vr-sessionid']: getSessionId(),
        ['x-vr-requestid']: generateRandomId(),
      },
    });

    if (!response.ok) {
      throw new Error('Token refresh failed');
    }

    const data = await response.json();

    const user = data.user;

    if (!isUserIdentityFragment(user)) {
      throw new Error('Invalid token refresh response');
    }

    if (user.isAuthenticated) {
      setStoredState(StorageKey.UserIdentity, (prev) => ({
        ...user,
        firstName: user.firstName || prev?.firstName,
        lastName: user.lastName || prev?.lastName,
        companyRoles: user.companyRoles || prev?.companyRoles,
      }));
    } else {
      clearStoredState(StorageKey.UserIdentity);
    }
  } catch (error) {
    clearStoredState(StorageKey.UserIdentity);
    log(error);
    throw error;
  }
};
