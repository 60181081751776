import { FetchResult, Observable, fromPromise, toPromise } from '@apollo/client';
import { ErrorLink, ErrorResponse } from '@apollo/client/link/error';

import { refreshTokens } from './refreshTokens';
import { ErrorCode } from './types.codegen';

export class TokenRefreshLink extends ErrorLink {
  #refreshInFlight?: Promise<any>;

  constructor() {
    super((error) => this.handleError(error));
  }

  private handleError({
    operation,
    graphQLErrors,
    forward,
  }: ErrorResponse): Observable<FetchResult> | void {
    if (!graphQLErrors) {
      return;
    }

    const tokensHaveExpired = graphQLErrors.some(
      (error) => error.extensions?.code === ErrorCode.AccessTokenExpired,
    );

    if (tokensHaveExpired) {
      if (!this.#refreshInFlight) {
        this.#refreshInFlight = refreshTokens().finally(() => {
          this.#refreshInFlight = undefined;
        });
      }

      return fromPromise(this.#refreshInFlight.then(() => toPromise(forward(operation))));
    }
  }
}
