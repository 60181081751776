import { OrderSummariesFragment, StrictTypedTypePolicies } from './types.codegen';

type UnpaidGroupOrderData = {
  items: Array<{ __ref: string }>;
};

export const typePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {
      searchJourney: {
        merge: (_, incoming) => incoming,
      },
      multiTicketUsages: {
        keyArgs: ['bookingId'],
        merge(existing, incoming, { args }) {
          // discard existing items if fetching the first page
          if (!args?.page || args.page === 1) {
            return incoming;
          }

          return {
            ...incoming,
            items: [...(existing?.items || []), ...incoming.items],
          };
        },
      },
      orders: {
        merge(
          existing: OrderSummariesFragment | undefined,
          incoming: OrderSummariesFragment,
          { args },
        ): OrderSummariesFragment {
          // override cached value if fetching for the first page
          if (args?.nextToken === undefined) {
            return incoming;
          }

          return {
            ...incoming,
            orderSummaries: [...(existing?.orderSummaries ?? []), ...incoming.orderSummaries],
          };
        },
        keyArgs(args) {
          return args ? Object.keys(args).filter((arg) => arg !== 'nextToken') : [];
        },
      },
      unpaidGroupOrders: {
        keyArgs: [],
        merge(
          existing: UnpaidGroupOrderData | undefined,
          incoming: UnpaidGroupOrderData,
          { args },
        ): UnpaidGroupOrderData {
          // override cached value if fetching for the first page
          if (args?.pagination.offset === 0) {
            return incoming;
          }

          const items = [...(existing?.items || []), ...(incoming.items || [])].reduce<
            UnpaidGroupOrderData['items']
          >((acc, order) => {
            if (!acc.some((existingOrder) => order.__ref === existingOrder.__ref)) {
              acc.push(order);
            }
            return acc;
          }, []);

          return { ...incoming, items };
        },
      },
      generalIncidents: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
      trainIncidents: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
    },
  },
  Order: {
    keyFields: ['orderNumber'],
  },
  OrderSummaries: {
    keyFields: false,
  },
  // We disable normalization of group journeys so that they are only accessible through their parent order or
  // sales session
  GroupJourney: {
    keyFields: false,
  },
  PassengerJourney: {
    keyFields: false,
  },
  JourneyLeg: {
    keyFields: false,
  },
  User: {
    keyFields: [],
    // Don't override existing fields with undefined, maintain at least one copy.
    // We don't want to wipe user.details from cache when query calls for user.tickets.
    merge: (existing, incoming, { mergeObjects }) => {
      return mergeObjects(existing, incoming);
    },
  },
  MultiTicketUsage: {
    keyFields: ['orderNumber', 'passengerJourneyId'],
  },
  SeatReservationOffer: {
    keyFields: false,
  },
  AdditionalReservationOffer: {
    keyFields: false,
  },
  JourneyOption: {
    keyFields: false,
  },
  JourneyOptionLeg: {
    keyFields: ['legKey'],
  },
  IncidentMessage: {
    keyFields: false,
  },
  GroupJourneyCabin: {
    fields: {
      passengers: {
        merge: (_, incoming) => incoming,
      },
    },
  },
  CrmCompany: {
    keyFields: ['companyNumber'],
  },
  Station: {
    fields: {
      timetableRows: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
    },
  },
};
