// generated by generate-worker-routes.ts
module.exports = [
  '/vaihda-yhteys-hakutulokset',
  '/matkan-tiedot',
  '/muokkaa-matkaa-maksaminen',
  '/maksuvahvistus',
  '/sarjalippu-matkahaun-tulokset',
  '/sarjalippu-oston-yhteenveto',
  '/sarjalippu-maksaminen',
  '/sarjalippu-maksuvahvistus',
  '/viive-lipun-lahetyksessa',
  '/ennakkotilaa-aamiainen',
  '/kertalippu-menomatkan-tiedot',
  '/kertalippu-paluumatkan-hakutulokset',
  '/kertalippu-paluumatkan-tiedot',
  '/yhteysbussi',
  '/kertalippu-ajoneuvon-tiedot',
  '/kertalippu-oston-yhteenveto',
  '/kertalippu-maksaminen',
  '/kertalippu-maksuvahvistus',
  '/en/change-connection-search-results',
  '/en/journey-details',
  '/en/edit-journey-payment',
  '/en/payment-confirmation',
  '/en/multi-ticket-search-results',
  '/en/multi-ticket-purchase-summary',
  '/en/multi-ticket-payment',
  '/en/multi-ticket-payment-confirmation',
  '/en/delay-in-ticket-delivery',
  '/en/preorder-breakfast',
  '/en/single-ticket-outbound-journey-details',
  '/en/single-ticket-return-search-results',
  '/en/single-ticket-return-journey-details',
  '/en/connecting-bus',
  '/en/single-ticket-vehicle-journey-details',
  '/en/single-ticket-purchase-summary',
  '/en/single-ticket-payment',
  '/en/single-ticket-payment-confirmation',
  '/sv/byt-forbindelse-sokresultat',
  '/sv/resans-information',
  '/sv/andra-resan-betalning',
  '/sv/betalningsbekraftelse',
  '/sv/seriebiljett-sokresultat',
  '/sv/seriebiljett-resans-sammanfattning',
  '/sv/seriebiljett-betalning',
  '/sv/seriebiljett-betalningens-bekraftelse',
  '/sv/foerdroejning-i-biljettleveransen',
  '/sv/forhandsbestall-morgonmal',
  '/sv/enkelbiljett-utresans-information',
  '/sv/enkelbiljett-returresans-sokresultat',
  '/sv/enkelbiljett-returresans-information',
  '/sv/anslutningsbuss',
  '/sv/enkelbiljett-fordonets-information',
  '/sv/enkelbiljett-resans-sammanfattning',
  '/sv/enkelbiljett-betalning',
  '/sv/enkelbiljett-betalningens-bekraftelse',
  '/payment-return/fi/singleTicket',
  '/payment-return/fi/multiTicket',
  '/payment-return/fi/meals',
  '/payment-return/fi/changeDeparture',
  '/payment-return/fi/groupSales',
  '/payment-return/fi/groupSalesMeals',
  '/payment-return/fi/changeSeat',
  '/payment-return/fi/addVehicles',
  '/payment-return/fi/AddBicycles',
  '/payment-return/fi/AddExternalBusLeg',
  '/payment-return/en/singleTicket',
  '/payment-return/en/multiTicket',
  '/payment-return/en/meals',
  '/payment-return/en/changeDeparture',
  '/payment-return/en/groupSales',
  '/payment-return/en/groupSalesMeals',
  '/payment-return/en/changeSeat',
  '/payment-return/en/addVehicles',
  '/payment-return/en/AddBicycles',
  '/payment-return/en/AddExternalBusLeg',
  '/payment-return/sv/singleTicket',
  '/payment-return/sv/multiTicket',
  '/payment-return/sv/meals',
  '/payment-return/sv/changeDeparture',
  '/payment-return/sv/groupSales',
  '/payment-return/sv/groupSalesMeals',
  '/payment-return/sv/changeSeat',
  '/payment-return/sv/addVehicles',
  '/payment-return/sv/AddBicycles',
  '/payment-return/sv/AddExternalBusLeg',
  '/fi/payment/mobilepay',
  '/en/payment/mobilepay',
  '/sv/payment/mobilepay',
];
