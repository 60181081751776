import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  MutationOptions,
  OperationVariables,
  from,
} from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { pushLogoutEvent } from 'analytics/userActions/pushLogoutEvent';

import { log } from 'logging/log';

import { StorageKey } from 'types/stateStore';

import { cleanupAccessCookies, removeSelectedRole } from 'utils/cookie';
import { isProduction } from 'utils/envs';
import { generateRandomId } from 'utils/misc';
import { clearPaymentInfos } from 'utils/paymentStore';
import { sessionStorage } from 'utils/storage';

import { ActiveQueriesLink } from './ActiveQueriesLink';
import fragmentIntrospection from './fragment-introspection';
import { updateUserDetails } from './mutations/updateUserDetails';
import { typePolicies } from './typePolicies';
import { ErrorCode } from './types.codegen';

const setSession = (sessionId: string) => setContext(() => ({ sessionId }));

if (!isProduction) {
  loadDevMessages();
  loadErrorMessages();
}

export class BackendClient extends ApolloClient<Record<string, unknown>> {
  static instance: BackendClient;
  uri: string;
  activeQueriesLink: ActiveQueriesLink;

  constructor(
    config: { uri: string; headers?: Record<string, string> },
    protected readonly sessionId: string,
    links: ApolloLink[] = [],
  ) {
    const activeQueriesLink = new ActiveQueriesLink();

    super({
      defaultOptions: {
        query: {
          fetchPolicy: 'no-cache',
          notifyOnNetworkStatusChange: true,
        },
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          notifyOnNetworkStatusChange: true,
        },
      },
      connectToDevTools: !isProduction,
      cache: new InMemoryCache({
        possibleTypes: fragmentIntrospection.possibleTypes,
        typePolicies,
      }),
      link: from([
        activeQueriesLink,
        ...links,
        onError(({ graphQLErrors, networkError, operation }) => {
          const requestId = operation.getContext().headers?.['x-vr-requestid'];
          if (requestId) {
            sessionStorage.setItem(
              StorageKey.LastKnownRequestId,
              // First block of uuid v4 is enough for us to find it in the logs
              // and short enough for user to pass to customer service
              JSON.stringify(requestId.split('-').at(0)),
            );
          }

          if (graphQLErrors) {
            graphQLErrors.forEach(async (error) => {
              // Disabled as noise for now. Appears to log many errors that are already logged as proper errors in Sentry.
              // Error would be logged as "Non-Error exception captured with keys: extensions, level, locations, message"
              // log(error, { errorContextTag: 'Apollo GraphQL' });
              if (!isAuthorized(error.extensions) && isTokenRefreshFailedError(error.extensions)) {
                cleanupAccessCookies();
                // Use clearStore instead of resetStore to avoid query in flight errors
                await this.clearStore();
                clearPaymentInfos();
                pushLogoutEvent();
              }

              if (isRoleNotFoundError(error.message)) {
                removeSelectedRole();
                setTimeout(() => {
                  // This delay is required for cookie write operation to catch on
                  this.refetchQueries({ include: 'active' });
                }, 100);
              }
            });
          }
          if (networkError) {
            log(networkError, { errorContextTag: 'Apollo Network' });
          }
        }),
        setSession(sessionId),
        setContext((_request, previousContext) => ({
          headers: {
            ...previousContext.headers,
            ['x-vr-sessionid']: previousContext.sessionId,
            ['x-vr-requestid']: generateRandomId(),
            ['x-bff-version']: 2,
          },
        })),
        new HttpLink({
          uri: config.uri,
          headers: config.headers,
          credentials: 'same-origin',
        }),
      ]),
    });

    this.activeQueriesLink = activeQueriesLink;
    this.uri = config.uri;
    BackendClient.instance = this;
  }

  async mutateData<T, TVariables extends OperationVariables>(
    options: MutationOptions<T, TVariables>,
  ) {
    const response = await this.mutate(options);
    if (!response.data) {
      throw new ApolloError({
        errorMessage: 'mutation data was not returned',
        extraInfo: response.errors,
      });
    }
    return response.data;
  }

  async resetStore() {
    await this.activeQueriesLink.waitActiveQueriesToComplete();
    return super.resetStore();
  }

  updateUserDetails = updateUserDetails(this);
}

const isAuthorized = (extensions?: { [key: string]: any }) => {
  if (!extensions) {
    return true;
  }
  return (
    extensions.code !== ErrorCode.Unauthenticated &&
    extensions.code !== ErrorCode.AccessTokenRefreshFailed
  );
};

const isTokenRefreshFailedError = (extensions?: { [key: string]: any }) =>
  extensions && extensions.code === ErrorCode.AccessTokenRefreshFailed;

const isRoleNotFoundError = (message?: string) =>
  message === 'Selected role cannot be found within the access token';
