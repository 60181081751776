'use client';
import Script from 'next/script';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useCookieConsent } from 'hooks/useCookieConsent';

import envs from 'utils/envs';

declare global {
  interface Window {
    grecaptcha?: ReCaptcha;
  }
}

interface ReCaptcha {
  execute: (siteKey: string, options: { action: string }) => Promise<string>;
  ready: (fn: () => void) => void;
}

const useTryAgain = (times: number, initialInterval: number) => {
  const [tries, setTries] = useState(0);
  const timeouts = useRef<NodeJS.Timeout[]>([]);
  const interval = useRef<number>(initialInterval);

  useEffect(() => {
    return () => {
      timeouts.current.map(clearTimeout);
    };
  }, [timeouts]);

  return useCallback(
    (fn: () => any) => {
      if (tries === times) return;

      const timeout = setTimeout(fn, interval.current);
      timeouts.current = [...timeouts.current, timeout];
      interval.current = interval.current * 2; // double interval on every try
      setTries(tries + 1);
    },
    [times, tries],
  );
};

export const ReCaptchaScript = () => {
  const tryAgain = useTryAgain(15, 1000);

  const [id, setId] = useState(0);

  const [{ consented }] = useCookieConsent();

  const reload = useCallback(() => setId((prev) => prev + 1), []);

  const onReady = useCallback(() => {
    if (!window.grecaptcha) {
      return;
    }

    const items = document.getElementsByClassName('grecaptcha-badge');

    for (const item of items) {
      item.setAttribute('aria-hidden', 'true');
    }
  }, []);

  useEffect(() => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(onReady);
    }
  }, [onReady]);

  const onLoad = useCallback(() => {
    window.grecaptcha?.ready(onReady);
  }, [onReady]);

  const onError = useCallback(() => {
    tryAgain(reload);
  }, [reload, tryAgain]);

  return consented ? (
    <Script
      key={id} // changing the key forces new Script component to be rendered
      src={`https://www.google.com/recaptcha/api.js?render=${envs.NEXT_PUBLIC_RECAPTCHA_V3_SITE_KEY}&id=${id}`}
      strategy="lazyOnload"
      onLoad={onLoad}
      onError={onError}
    />
  ) : null;
};
