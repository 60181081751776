import Head from 'next/head';
import { useEffect, useState } from 'react';

const isLinkElement = (node: Node): node is HTMLLinkElement =>
  node.nodeType === node.ELEMENT_NODE && node.nodeName === 'LINK';

/**
 * HACK: This is a dirty hack for issues with CSS Modules
 *
 * Prevent styles from disappearing when navigating.
 *
 * Related issues:
 * https://github.com/vercel/next.js/issues/40080
 * https://github.com/vercel/next.js/issues/33286
 */
export const KeeperOfStyles = () => {
  const [styles, setStyles] = useState<string[]>([]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    const observer = new MutationObserver((mutations) => {
      const removedStyles = mutations
        .flatMap((mutation) => Array.from(mutation.removedNodes))
        .filter(isLinkElement)
        .filter((linkElement) => linkElement.rel === 'stylesheet' && linkElement.href)
        .map((linkElement) => linkElement.href);

      if (removedStyles.length) {
        setStyles((prevStyles) => [
          ...prevStyles,
          ...removedStyles.filter((style) => !prevStyles.includes(style)),
        ]);
      }
    });

    observer.observe(document.head, { childList: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Head>
      {styles.map((href, i) => (
        <link key={i} rel="stylesheet" href={href} />
      ))}
    </Head>
  );
};
