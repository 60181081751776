import { ApolloLink, NextLink, Operation } from '@apollo/client';

export class ActiveQueriesLink extends ApolloLink {
  private activeQueries = 0;
  private promiseWithResolvers: PromiseWithResolvers<void> | null = null;

  request(operation: Operation, forward: NextLink) {
    this.activeQueries++;
    return forward(operation).map((result) => {
      this.activeQueries--;

      if (this.activeQueries === 0 && this.promiseWithResolvers) {
        this.promiseWithResolvers.resolve();
        this.promiseWithResolvers = null;
      }

      return result;
    });
  }

  async waitActiveQueriesToComplete() {
    if (this.activeQueries === 0) {
      return;
    }

    if (!this.promiseWithResolvers) {
      this.promiseWithResolvers = Promise.withResolvers();
    }

    return this.promiseWithResolvers.promise;
  }
}
