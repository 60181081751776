'use client';
import { useReportWebVitals } from 'next/web-vitals';
import React from 'react';

import { reportWebVitals } from 'analytics/reportWebVitals';

import 'build/polyfills';

import { ConvertComScript } from 'components/structural/ConvertComScript/ConvertComScript';
import { KeeperOfStyles } from 'components/structural/KeeperOfStyles';

import { useCacheRoutes } from 'hooks/useCacheRoutes';
import { useDynamicViewportHeight } from 'hooks/useDynamicViewportHeight';
import { useFocusNewHeadings } from 'hooks/useFocusNewHeadings';

function AppClient() {
  useDynamicViewportHeight();
  useCacheRoutes();
  useFocusNewHeadings();
  useReportWebVitals(reportWebVitals);

  return (
    <>
      <KeeperOfStyles />
      <ConvertComScript />
    </>
  );
}

export default React.memo(AppClient);
