import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useMemo } from 'react';

import { GetUserDetailsQuery, GetUserDetailsQueryVariables } from 'backend/types.codegen';

import { UserFragment } from '../fragments/backend-fragments';

export const GET_USER_DETAILS_QUERY = gql`
  ${UserFragment}
  query getUserDetails {
    userStatus {
      ...User

      ... on NotLoggedIn {
        notLoggedIn
      }
    }
  }
`;

export const useGetUserDetails = () => {
  const result = useQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(
    GET_USER_DETAILS_QUERY,
    {
      ssr: false,
      fetchPolicy: 'cache-first',
      // Needs to be 'all' in order to pass data through even in the cases where the user profile fetch fails on CRM
      errorPolicy: 'all',
    },
  );

  return useMemo(
    () => ({
      ...result,
      // data is provided in backwards compatible way, data is undefined if not logged in.
      data:
        result.data?.userStatus.__typename === 'User'
          ? {
              user: result.data.userStatus,
            }
          : undefined,
    }),
    [result],
  );
};
